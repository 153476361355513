export const URL_PARAMS = {
  TASK: '/:taskId'
};

export const ROUTES = {
  DEFAULT: '/',
  LOGIN_RETURN: '/redirect_overview',
  LOGOUT_REDIRECT: '/redirect_logout',
  REPORTS: '/vehicle_reports',
  UMPARKEN: '/umparken',
  OVERVIEW: '/overview',
  PICKUP: {
    ROOT: '/pickup-tour'
  },
  SERVICE: {
    ROOT: '/service-tour'
  },
  CUSTOM: {
    ROOT: '/custom-tour'
  },
  RELOCATE: '/relocate',
  WORKSHOP: {
    ROOT: '/workshop',
    REPAIR: '/workshop/task/:taskId',
    ONSTREET: '/workshop/on-street-repair'
  },
  TOUR: {
    PREPARE: '/prepare',
    RUN: '/run',
    POST_STEPS: '/post-steps',
    EXTEND: '/extend',
    FEEDBACK: '/feedback'
  },
  LOGIN: '/login'
} as const;
